import { Controller } from '@hotwired/stimulus'

// Sends analytics events to backend
//
export default class extends Controller {
  static targets = ['analyticsLink']

  declare readonly analyticsLinkTarget: HTMLAnchorElement

  connect(): void {
    this.analyticsLinkTarget.addEventListener('click', this.trackEvent.bind(this))
  }

  trackEvent(event: MouseEvent) {
    event.preventDefault()
    const url = this.analyticsLinkTarget.href
    const eventName = this.analyticsLinkTarget.getAttribute('data-userevents-event-name') || 'link_click'

    const data = new FormData()
    data.append('eventName', eventName)
    data.append('authenticity_token', this.getCSRFToken())

    // Submit to beacon API
    navigator.sendBeacon('/analytics', data)

    // Use setTimeout to ensure the beacon is sent before navigating away
    setTimeout(() => {
      window.location.href = url
    }, 100)
  }

  private getCSRFToken() {
    // @ts-ignore
    return document.querySelector('meta[name="csrf-token"]').content
  }
}
